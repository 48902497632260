import React from "react"
import styled from "styled-components"
import theme from "../styles/GlobalVariables"

const FacebookFeed = () => {
  return (
    <FacebookFeedWrapper>
      <div
        className="fb-page"
        data-href="https://www.facebook.com//The-Office-Bar-and-Grill-301104340578376/"
        data-tabs="timeline"
        data-width=""
        data-height=""
        data-small-header="false"
        data-adapt-container-width="true"
        data-hide-cover="false"
        data-show-facepile="true"
      >
        <blockquote
          cite="https://www.facebook.com/The-Office-Bar-and-Grill-301104340578376/"
          className="fb-xfbml-parse-ignore"
        >
          <a href="https://www.facebook.com/The-Office-Bar-and-Grill-301104340578376/">
            The Office Bar and Grill
          </a>
        </blockquote>
      </div>
    </FacebookFeedWrapper>
  )
}

const FacebookFeedWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .fb-page {
    min-width: 300px;
  }
`

export default FacebookFeed
